import { Icon } from '@iconify/react';
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import PageHeading from '../PageHeading';
import Spacing from '../Spacing';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import serverConfig from '../../helper/serverConfig';

const AppointmentPlanner = () => {
    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        phone: '',
        date: '',
        time: '',
        duration: 30,
        subject: 'Développement Web',
        notes: '',
    });
    
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        try {
            // Option 1: Utilisez votre API existante pour l'envoi d'emails
            const response = await fetch(`${serverConfig.serverUrl}?action=appointment&token=${serverConfig.authToken}`, {
                method: 'POST',
                headers: { 
                    'Content-Type': 'application/json; charset=utf-8',
                },
                body: JSON.stringify(formData)
            });

            console.log('Rendez-vous envoyé avec succès', response.data);
            setSuccessMessage('Votre rendez-vous a bien été enregistré !');
            setErrorMessage('');
            
            // Reset form
            setFormData({
                fullName: '',
                email: '',
                phone: '',
                date: '',
                time: '',
                duration: 30,
                subject: 'Développement Web',
                notes: '',
            });
        } catch (error) {
            console.error('Erreur lors de l\'envoi du formulaire', error);
            setErrorMessage('Une erreur est survenue. Veuillez réessayer ultérieurement.');
        } finally {
            setIsSubmitting(false);
        }
    };

    const subjects = [
        'Développement Web',
        'Développement Front-end',
        'Développement Back-end',
        'UI/UX Design',
        'WordPress',
        'SEO',
        'Performance Web',
        'Consultation Technique',
        'Autre'
    ];

    return (
        <>
            <Helmet>
                <meta name="description" content="Prenez rendez-vous avec notre équipe pour discuter de votre projet web. Nous sommes disponibles pour vous accompagner dans la réalisation de vos idées." />
            </Helmet>
            <PageHeading
                title="Prise de Rendez-vous"
                bgSrc="/images/portfolio_hero_bg.jpeg"
                pageLinkText="Rendez-vous"
            />
            <Spacing lg="150" md="80" />
            <Div className="container">
                <Div className="row">
                    <Div className="col-lg-6">
                        <SectionHeading
                            title="Réservez un créneau"
                            subtitle="Planifiez un rendez-vous pour discuter de votre projet web et obtenir des conseils personnalisés."
                        />
                        <Spacing lg="55" md="30" />
                        <Div className="cs-contact_info">
                            <h4 className="cs-contact_info_title">Pourquoi prendre rendez-vous ?</h4>
                            <ul className="cs-contact_info_list">
                                <li>
                                    <Icon icon="mdi:lightbulb-on-outline" />
                                    <span>Discuter de vos besoins spécifiques</span>
                                </li>
                                <li>
                                    <Icon icon="mdi:file-document-outline" />
                                    <span>Obtenir un devis personnalisé</span>
                                </li>
                                <li>
                                    <Icon icon="mdi:clock-outline" />
                                    <span>Définir une feuille de route pour votre projet</span>
                                </li>
                                <li>
                                    <Icon icon="mdi:shield-check-outline" />
                                    <span>Poser toutes vos questions à nos experts</span>
                                </li>
                            </ul>
                        </Div>
                        <Spacing lg="0" md="50" />
                    </Div>
                    <Div className="col-lg-6">
                        <form onSubmit={handleSubmit} className="row">
                            <Div className="col-sm-12">
                                <label className="cs-primary_color">Nom complet*</label>
                                <input 
                                    type="text" 
                                    className="cs-form_field" 
                                    name="fullName"
                                    value={formData.fullName}
                                    onChange={handleChange}
                                    required 
                                />
                                <Spacing lg="20" md="20" />
                            </Div>
                            <Div className="col-sm-6">
                                <label className="cs-primary_color">E-mail*</label>
                                <input 
                                    type="email" 
                                    className="cs-form_field" 
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    required 
                                />
                                <Spacing lg="20" md="20" />
                            </Div>
                            <Div className="col-sm-6">
                                <label className="cs-primary_color">Téléphone*</label>
                                <input 
                                    type="tel" 
                                    className="cs-form_field" 
                                    name="phone"
                                    value={formData.phone}
                                    onChange={handleChange}
                                    required 
                                />
                                <Spacing lg="20" md="20" />
                            </Div>
                            <Div className="col-sm-6">
                                <label className="cs-primary_color">Date*</label>
                                <input 
                                    type="date" 
                                    className="cs-form_field" 
                                    name="date"
                                    value={formData.date}
                                    onChange={handleChange}
                                    required 
                                />
                                <Spacing lg="20" md="20" />
                            </Div>
                            <Div className="col-sm-6">
                                <label className="cs-primary_color">Heure*</label>
                                <input 
                                    type="time" 
                                    className="cs-form_field" 
                                    name="time"
                                    value={formData.time}
                                    onChange={handleChange}
                                    required 
                                />
                                <Spacing lg="20" md="20" />
                            </Div>
                            <Div className="col-sm-6">
                                <label className="cs-primary_color">Sujet*</label>
                                <select 
                                    className="cs-form_field" 
                                    name="subject"
                                    value={formData.subject}
                                    onChange={handleChange}
                                    required
                                >
                                    {subjects.map(subject => (
                                        <option key={subject} value={subject}>
                                            {subject}
                                        </option>
                                    ))}
                                </select>
                                <Spacing lg="20" md="20" />
                            </Div>
                            <Div className="col-sm-6">
                                <label className="cs-primary_color">Durée</label>
                                <select 
                                    className="cs-form_field" 
                                    name="duration"
                                    value={formData.duration}
                                    onChange={handleChange}
                                >
                                    <option value={15}>15 minutes</option>
                                    <option value={30}>30 minutes</option>
                                    <option value={45}>45 minutes</option>
                                    <option value={60}>1 heure</option>
                                    <option value={90}>1 heure 30 minutes</option>
                                    <option value={120}>2 heures</option>
                                </select>
                                <Spacing lg="20" md="20" />
                            </Div>
                            <Div className="col-sm-12">
                                <label className="cs-primary_color">Notes / Détails du projet</label>
                                <textarea
                                    cols="30"
                                    rows="7"
                                    className="cs-form_field"
                                    name="notes"
                                    value={formData.notes}
                                    onChange={handleChange}
                                    placeholder="Décrivez brièvement votre projet ou les points que vous souhaitez aborder"
                                ></textarea>
                                <Spacing lg="25" md="25" />
                            </Div>
                            <Div className="col-sm-12">
                                <button className="cs-btn cs-style1" disabled={isSubmitting}>
                                    {isSubmitting ? (
                                        <span>Traitement en cours...</span>
                                    ) : (
                                        <>
                                            <span>Réserver le rendez-vous</span>
                                            <Icon icon="bi:arrow-right" />
                                        </>
                                    )}
                                </button>
                            </Div>
                            <Div className="col-sm-12">
                                <Spacing lg="20" md="20" />
                                {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
                                {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                            </Div>
                        </form>
                    </Div>
                </Div>
            </Div>
            <Spacing lg="150" md="80" />
        </>
    );
};

export default AppointmentPlanner;