import { Route, Routes } from 'react-router-dom';
import ContactPage from './components/Pages/ContactPage';
import ErrorPage from './components/Pages/ErrorPage';
import Home from './components/Pages/Home';
import PortfolioDetailsPage from './components/Pages/PortfolioDetailsPage';
import ServiceDetailsPage from './components/Pages/ServiceDetailsPage';
import ConceptionPage from './components/Pages/ConceptionPage';
import MaintenancePage from './components/Pages/MaintenancePage';
import IdentitePage from './components/Pages/IdentitePage';
import ServicesPage from './components/Pages/ServicesPage';
import ReferencementNatPage from './components/Pages/ReferencementNatPage';
import ReferencementPayPage from './components/Pages/ReferencementPayPage';
import PortfolioPage from './components/Pages/PortfolioPage';
import Layout from './components/Layout';
import CGUPage from './components/Pages/CGUPage';
import CGVPage from './components/Pages/CGVPage';
import AppointmentPlanner from './components/Pages/PlanAppointment';

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="service" element={<ServicesPage />} />
          <Route
            path="service/serviceDetails"
            element={<ServiceDetailsPage />}
          />
          <Route
            path="service/conception-site-web"
            element={<ConceptionPage />}
          />
          <Route
            path="service/maintenance-site-web"
            element={<MaintenancePage />}
          />
          <Route
            path="service/identite-visuelle"
            element={<IdentitePage />}
          />
          <Route
            path="service/referencement-naturel"
            element={<ReferencementNatPage />}
          />
          <Route
            path="service/referencement-payant"
            element={<ReferencementPayPage />}
          />
          <Route
            path="conditions-generales-dutilisation"
            element={<CGUPage />}
          />
          <Route
            path="conditions-generales-de-vente"
            element={<CGVPage />}
          />
          <Route
            path="rdv"
            element={<AppointmentPlanner />}
          />
          <Route path="portfolio" element={<PortfolioPage />} />
          <Route
            path="portfolio/:portfolioDetailsId"
            element={<PortfolioDetailsPage />}
          />
          <Route path="contact" element={<ContactPage />} />
        </Route>
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </>
  );
}

export default App;
